import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Make sure to add your logo to this path
import './Navbar.css';
import { LanguageContext } from '../contexts/LanguageContext';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('preferredLanguage', lang);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Restart Online Logo" />
        </Link>
        
        <div className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/" className="navbar-item" onClick={closeMenu}>
            {language === 'kz' ? 'Басты бет' : 'Главная'}
          </Link>
          <a href="/#about" className="navbar-item" onClick={closeMenu}>
            {language === 'kz' ? 'Біз туралы' : 'О нас'}
          </a>
          <a href="/#programs" className="navbar-item" onClick={closeMenu}>
            {language === 'kz' ? 'Бағдарламалар' : 'Программы'}
          </a>
          <a href="/#contact" className="navbar-item" onClick={closeMenu}>
            {language === 'kz' ? 'Байланыс' : 'Контакты'}
          </a>
          
          <div className="language-switcher">
            <button 
              className={`language-btn ${language === 'kz' ? 'active' : ''}`} 
              onClick={() => changeLanguage('kz')}
            >
              KAZ
            </button>
            <button 
              className={`language-btn ${language === 'ru' ? 'active' : ''}`} 
              onClick={() => changeLanguage('ru')}
            >
              RUS
            </button>
          </div>
        </div>
        
        <div className="navbar-mobile-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
