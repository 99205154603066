import React, { useContext } from 'react';
import './Offer.css';
import { LanguageContext } from '../contexts/LanguageContext';

function Offer() {
  const { language } = useContext(LanguageContext);

  // Контент на казахском и русском языках
  const content = {
    kz: {
      title: "www.restartonline.kz интернет-порталы пайдаланушыларына арналған жария ұсыныс",
      intro: "Бұл құжат «BRAND ONLINE KZ» ЖШС атқарушы директорының, Жарғы негізінде әрекет ететін, қызмет көрсету шартын төменде көрсетілген шарттар бойынша жасау туралы ресми ұсынысын (жария ұсыныс) білдіреді.",
      
      sections: [
        {
          title: "1. Терминдер және анықтамалар",
          content: [
            "1.1. Осы Жария ұсыныста келесі терминдер қолданылады:",
            "Орындаушы — «BRAND ONLINE KZ» ЖШС, қашықтықтан оқыту қызметтерін көрсететін.",
            "Тапсырыс беруші — осы ұсыныстың шарттарын қабылдаған жеке немесе заңды тұлға.",
            "Платформа — Орындаушының restartonline.kz мекенжайында орналасқан веб-сайты.",
            "Қызметтер — Платформа арқылы Орындаушы ұсынатын білім беру қызметтері.",
            "Ұсынысты қабылдау — осы ұсыныстың шарттарын толық және сөзсіз қабылдау."
          ]
        },
        {
          title: "2. Ұсыныс мәні",
          content: [
            "2.1. Осы ұсыныстың мәні Тапсырыс берушіге осы ұсыныстың шарттарына және Орындаушының сайтында жарияланған тарифтерге сәйкес білім беру қызметтерін ұсыну болып табылады.",
            "2.2. Осы ұсыныс және оған қосымшалар ресми құжаттар болып табылады және restartonline.kz сайтында жарияланады.",
            "2.3. Орындаушы Тапсырыс берушімен алдын ала келісімсіз осы ұсыныстың шарттарын бір жақты тәртіппен өзгертуге құқылы, бұл ретте өзгертілген шарттарды күшіне енгізуге дейін кемінде бір күн бұрын сайтта жариялауды қамтамасыз етеді."
          ]
        },
        {
          title: "3. Қызмет көрсету шарттары мен тәртібі",
          content: [
            "3.1. Қызметтер тізімі және олардың құнымен танысып шығып, Тапсырыс беруші қажетті оқыту нұсқасын таңдайды.",
            "3.2. Тапсырысты рәсімдеу үшін Тапсырыс беруші сайттағы электрондық өтінім нысанын толтыруы немесе Орындаушымен телефон арқылы байланысуы керек.",
            "3.3. Өтінімді рәсімдеу және қызметтерді төлеуден кейін Тапсырыс берушіге таңдалған материалдар мен курстарға қол жеткізу беріледі.",
            "3.4. Егер қызмет көрсету аяқталған сәттен бастап үш күн ішінде Тапсырыс беруші шағым білдірмесе, қызметтер тиісті түрде және толық көлемде көрсетілген болып саналады."
          ]
        },
        {
          title: "4. Қызметтер құны және есеп айырысу тәртібі",
          content: [
            "4.1. Қызметтердің құны сайтта жарияланған қолданыстағы тарифтерге сәйкес анықталады.",
            "4.2. Төлем Орындаушының есеп шотына қолма-қол ақшасыз тәртіппен 100% алдын ала төлеммен жүргізіледі.",
            "4.3. Жекелеген жағдайларда, Орындаушымен келісім бойынша, төлемді бөліп төлеу мүмкіндігі бар."
          ]
        },
        {
          title: "5. Тараптардың құқықтары мен міндеттері",
          content: [
            "Орындаушы міндеттенеді:",
            "Төлемнен кейін оқу материалдарына қол жеткізуді қамтамасыз ету;",
            "Тапсырыс берушінің жеке деректерінің құпиялылығын сақтау;",
            "Қызмет көрсету мәселелері бойынша ақпараттық қолдау көрсету.",
            "Тапсырыс беруші міндеттенеді:",
            "Орындаушының қызметтерін уақытылы төлеу;",
            "Тіркеу кезінде шынайы ақпарат беру;",
            "Оқу материалдарын таратпау және үшінші тұлғаларға қол жеткізуді бермеу."
          ]
        },
        {
          title: "6. Қорытынды ережелер",
          content: [
            "6.1. Ұсыныс Тапсырыс берушінің оны қабылдауы сәтінен бастап күшіне енеді және тараптардың барлық міндеттемелерді орындау сәтіне дейін қолданылады.",
            "6.2. Барлық даулар мен келіспеушіліктер тараптардың келіссөздері арқылы шешіледі.",
            "6.3. Шартты орындауға байланысты мәселелер бойынша <a href=\"mailto:office@restartonline.kz\" className=\"contact-email\">office@restartonline.kz</a> электрондық пошта мекенжайына хабарласуға болады."
          ]
        }
      ]
    },
    ru: {
      title: "Публичная оферта для пользователей интернет-портала www.restartonline.kz",
      intro: "Настоящий документ представляет собой официальное предложение (публичную оферту) ТОО \"BRAND ONLINE KZ\", в лице исполнительного директора, действующего на основании Устава, о заключении Договора на оказание услуг на изложенных ниже условиях.",
      
      sections: [
        {
          title: "1. Термины и определения",
          content: [
            "1.1. В настоящей Публичной оферте используются следующие термины:",
            "Исполнитель — ТОО \"BRAND ONLINE KZ\", оказывающее услуги дистанционного обучения.",
            "Заказчик — физическое или юридическое лицо, принявшее условия настоящей оферты.",
            "Платформа — веб-сайт Исполнителя, расположенный по адресу restartonline.kz.",
            "Услуги — образовательные услуги, предоставляемые Исполнителем посредством Платформы.",
            "Акцепт оферты — полное и безоговорочное принятие условий настоящей оферты."
          ]
        },
        {
          title: "2. Предмет оферты",
          content: [
            "2.1. Предметом настоящей оферты является предоставление Заказчику образовательных услуг в соответствии с условиями настоящей оферты и тарифами, опубликованными на сайте Исполнителя.",
            "2.2. Настоящая оферта и приложения к ней являются официальными документами и публикуются на сайте restartonline.kz.",
            "2.3. Исполнитель имеет право изменять условия данной оферты в одностороннем порядке без предварительного согласования с Заказчиком, обеспечивая при этом публикацию измененных условий на сайте не менее чем за один день до их ввода в действие."
          ]
        },
        {
          title: "3. Условия и порядок предоставления услуг",
          content: [
            "3.1. Ознакомившись с перечнем услуг и их стоимостью, Заказчик выбирает необходимый вариант обучения.",
            "3.2. Для оформления Заказа Заказчик должен заполнить электронную форму заявки на сайте или связаться с Исполнителем по телефону.",
            "3.3. После оформления заявки и оплаты услуг Заказчику предоставляется доступ к выбранным материалам и курсам.",
            "3.4. Услуги считаются оказанными надлежащим образом и в полном объеме, если в течение трех дней с момента окончания оказания услуг Заказчиком не выставлена претензия."
          ]
        },
        {
          title: "4. Стоимость услуг и порядок расчетов",
          content: [
            "4.1. Стоимость услуг определяется в соответствии с действующими тарифами, опубликованными на сайте.",
            "4.2. Оплата производится 100% предоплатой в безналичном порядке на расчетный счет Исполнителя.",
            "4.3. В отдельных случаях, по согласованию с Исполнителем, возможна рассрочка платежа."
          ]
        },
        {
          title: "5. Права и обязанности сторон",
          content: [
            "Исполнитель обязуется:",
            "Обеспечить доступ к учебным материалам после оплаты;",
            "Сохранять конфиденциальность персональных данных Заказчика;",
            "Оказывать информационную поддержку по вопросам оказания услуг.",
            "Заказчик обязуется:",
            "Своевременно оплачивать услуги Исполнителя;",
            "Предоставлять достоверную информацию при регистрации;",
            "Не распространять учебные материалы и не передавать доступ третьим лицам."
          ]
        },
        {
          title: "6. Заключительные положения",
          content: [
            "6.1. Оферта вступает в силу с момента ее акцепта Заказчиком и действует до момента исполнения сторонами всех обязательств.",
            "6.2. Все споры и разногласия решаются путем переговоров сторон.",
            "6.3. По вопросам, связанным с исполнением Договора, обращаться по адресу электронной почты: <a href=\"mailto:office@restartonline.kz\" className=\"contact-email\">office@restartonline.kz</a>."
          ]
        }
      ]
    }
  };

  // Текущий контент в зависимости от выбранного языка
  const currentContent = content[language];

  const renderContent = (content) => {
    if (typeof content === 'string') {
      if (content.includes('<a')) {
        return <p dangerouslySetInnerHTML={{ __html: content }} />;
      }
      return <p>{content}</p>;
    }
    
    return (
      <ul className="terms-list">
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className="offer-container">
      <div className="offer-content">
        <h1 className="offer-title">{currentContent.title}</h1>
        <p className="offer-intro">{currentContent.intro}</p>

        {currentContent.sections.map((section, index) => (
          <div className="offer-section" key={index}>
            <h2 className="section-title">{section.title}</h2>
            {section.content.map((item, itemIndex) => (
              <React.Fragment key={itemIndex}>
                {renderContent(item)}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Offer;
  