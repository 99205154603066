import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo from '../assets/logo.png';
import { LanguageContext } from '../contexts/LanguageContext';

function Home() {
  const { language } = useContext(LanguageContext);

  // Контент на казахском и русском языках
  const content = {
    kz: {
      heroTitle: "Restart мектебі - заманауи онлайн білім беру",
      heroSubtitle: "Білім арқылы болашағыңызды жаңадан бастаңыз",
      startLearning: "Оқуды бастау",
      learnMore: "Толығырақ білу",
      
      advantagesTitle: "Біздің артықшылықтарымыз",
      
      advantages: [
        {
          icon: "📱",
          title: "Мобильді оқыту",
          text: "Біздің бейімделгіш қосымшамен кез келген уақытта және кез келген жерде оқыңыз"
        },
        {
          icon: "👨‍🏫",
          title: "Тәжірибелі оқытушылар",
          text: "Біздің сарапшылардың өз салаларында практикалық жұмыс тәжірибесі бар"
        },
        {
          icon: "🎓",
          title: "Сертификаттау",
          text: "Жұмыс берушілер мойындайтын сертификат алыңыз"
        }
      ],
      
      aboutTitle: "Біздің миссиямыз",
      aboutText1: "Онлайн мектебіміздің басты мақсаты – алған білімді және дағдыларды өмірде тиімді қолдана алатын, қоғамға пайдалы азаматтарды тәрбиелеу. Біз әрбір оқушыға дара жол көрсетіп, олардың өз мақсаттарына сенімді түрде жетуіне қолдау көрсетеміз.",
      aboutText2: "Біздің пайым – сапалы білім арқылы жауапты, саналы және білімді ұрпақ қалыптастыру.",
      contactUs: "Бізбен байланысу",
      
      // Новые секции
      valuesTitle: "Біздің құндылықтарымыз",
      values: [
        {
          number: "1️⃣",
          title: "Жеке тұлғаны дамыту",
          text: "Әрбір оқушының ерекшелігін ескере отырып, олардың әлеуетін ашуға көмектесеміз."
        },
        {
          number: "2️⃣",
          title: "Өмір бойы білім алу",
          text: "Біз тек теорияны ғана емес, өмірде қолдануға болатын білімді және дағыдыларды үйретеміз және үздіксіз білім алуды насихаттаймыз."
        },
        {
          number: "3️⃣",
          title: "Жауапкершілік",
          text: "Өз білімін қоғам игілігі үшін пайдалана алатын саналы азаматтарды тәрбиелеу – басты құндылығымыз."
        },
        {
          number: "4️⃣",
          title: "Шығармашылық ойлау және инновация",
          text: "Оқушыларымыздың шығармашылық қабілеттерін дамытуға және оларды заман талабына сай технологиялармен жұмыс істеуге бейімдеу арқылы жаңа идеялар ұсынып, тиімді шешімдер табуына ықпал етеміз."
        },
        {
          number: "5️⃣",
          title: "Қоғамға қызмет",
          text: "Біздің оқушылар – болашақ көшбасшылар, олар өз білімдері мен дағдыларын қоғамның игілігі үшін пайдаланады."
        }
      ],

      programsTitle: "Мектебімізге қош келдіңіз! 🎓✨",
      programsIntro: "Біз білімді тек беру емес, оны өмірге бейімдеу маңызды деп санаймыз. Сондықтан мектеп бағдарламасына төмендегі пәндерді ерекше тәсілмен енгіздік:",
      
      programs: [
        {
          icon: "🔢",
          title: "Математика",
          intro: "Математика – ақыл-ойдың гимнастикасы! Бұл пән арқылы оқушылар логикалық ойлау қабілеттерін шыңдап, кез келген өмірлік есепті шешуге дағдыланады.",
          text: "Бұл пән логикалық ойлауды дамытып, кез келген пәнді оңай игеруге көмектеседі. Математиканы жақсы меңгерген оқушы:\n✔ Ми жұмысын реттеп, есте сақтау қабілетін жақсартады.\n✔ Өмірдегі маңызды шешімдерді оңай қабылдай алады.\nМатематика – табысты болашақтың негізі! 🌟"
        },
        {
          icon: "🌍",
          title: "Ағылшын тілі",
          intro: "Ағылшын тілі – әлемге ашылған терезе!",
          text: "Халықаралық байланыс құралы ретінде біздің оқушылар бұл тілде еркін сөйлеп, болашақта жаһандық жетістікке жетуге мүмкіндік алады."
        },
        {
          icon: "💻",
          title: "Информатика және бағдарламалау",
          intro: "Қазіргі заман талабына сай болу үшін АКТ және бағдарламалау дағдылары өте маңызды.",
          text: "Біз оқушыларға Алгоритм курсы арқылы цифрлық әлемнің қыр-сырын үйретеміз."
        },
        {
          icon: "🧠",
          title: "Логикалық есептер",
          intro: "Логикалық есептер – шығармашылық ойлаудың негізі.",
          text: "Бұл курста оқушылар логикалық қабілеттерін дамытып, әр деңгейдегі күрделі тапсырмаларды оңай шешуге үйренеді."
        }
      ],

      programsOutro: "✨ Бізбен бірге жан-жақты даму мүмкіндігіне ие боласыз!",

      approachTitle: "Біздің тәсіліміз",
      approaches: [
        {
          title: "Жеке даму траекториясы",
          text: "Әр оқушы сабақтарды жеке даму траекториясы бойынша игереді. Бұл жүйеде оқушының деңгейіне сәйкес тапсырмалар беріледі, осылайша әр бала өз мүмкіндіктерін толық пайдалана алады.\nСонымен қатар, оқушылар менторларымен бірге жеке сабақтарда қиындықтарын талқылап, шешімін таба алады. Бұл тәсіл оқушыларға білім алу үдерісін жеңілдетіп, нәтижелі етеді."
        },
        {
          title: "Ата-анамен тұрақты байланыс",
          text: "Ментор оқушының күнделікті, апталық және айлық үлгерімі туралы ақпаратты ата-анасына тұрақты түрде жіберіп отырады. Бұл ата-аналарға баласының жетістіктерін бақылауға және оқу барысына белсенді қатысуға мүмкіндік береді."
        },
        {
          title: "Сынып жетекшісімен байланыс",
          text: "Ментор оқушының офлайн мектебіндегі сынып жетекшісімен тығыз байланыс орнатады. Бұл оқушының жалпы оқу үлгерімін жан-жақты бақылауға және офлайн мектептегі жетістіктерін де ескеруге мүмкіндік береді."
        }
      ],

      activitiesTitle: "Арнайы бағдарламалар мен іс-шаралар",
      activities: [
        {
          icon: "🎮",
          title: "Геймификация арқылы қорытындылау жұмыстары",
          text: "Ойын элементтерін оқу процесіне қосу арқылы оқушылардың қызығушылығын арттырамыз. Өткен тақырыптарды игеру үшін тесттер мен тапсырмалар ойын түрінде беріледі."
        },
        {
          icon: "🏆",
          title: "Апталық марафон жарыстары",
          text: "Аптасына бір рет оқушылар арасында марафон жарыстары ұйымдастырылып, олардың белсенділігін арттыруға және ынталандыруға арналған сыйлықтар беріледі."
        },
        {
          icon: "📝",
          title: "Апталық және айлық тестілер жинағы",
          text: "Оқушылардың өз деңгейінде жаңа сабақтарды игеруіне мүмкіндік беретін тестілер жинағы әзірленген. Тест нәтижелеріне қарай оқушылар топтастырылып, жеке деңгейлері бойынша білім алады."
        },
        {
          icon: "📊",
          title: "Портфолио жинақтау және диагностика жасау",
          text: "Әр оқушының кішігірім жетістіктері арнайы портфолиоға жинақталады. Бұл тәсіл оқушының даму динамикасын қадағалап, нақты әлсіз тұстарын анықтауға мүмкіндік береді."
        },
        {
          icon: "🏅",
          title: "Олимпиадалар мен жарыстар",
          text: "Оқушылардың білімін жетілдіру және жоғары нәтижелерге қол жеткізу үшін пәндік олимпиадалар мен түрлі жарыстар өткізіледі."
        }
      ],

      thematicWeeksTitle: "Апталық тақырыптық іс-шаралар",
      thematicWeeks: [
        "Бірінші апта: Жасанды интеллект (ЖИ) апталығы", 
        "Екінші апта: Спорт апталығы", 
        "Үшінші апта: Ақпараттық-коммуникациялық технологиялар (ИКТ) апталығы", 
        "ТикТок апталығы: Социалдық желіні дұрыс пайдалану мақсатында",
        "Апта күні: Тек аудио немесе тек видео арқылы сөйлесу"
      ],
      thematicWeeksOutro: "Бұл апталар қайталанып, әрдайым жаңа мазмұнмен толығып отырады.",

      psychologyTitle: "Психолог және профориентолог жұмысы",
      psychologyText: "Оқушылардың жеке дамуы мен болашақ мамандық таңдауына көмектесу үшін психолог және профориентолог мамандары тұрақты түрде қолдау көрсетеді. Біз психологиялық тест, жеке анализ жасап, ата-анаға ұсыныс береміз және видео арқылы түсіндіреміз. Профориентолог мамандары оқушыларға болашақ мамандық таңдауда бағыт-бағдар беруге көмектеседі.",

      activitiesOutro: "✨ Біз білім беруді қызықты әрі тиімді ету үшін бар күшімізді саламыз. Оқушыларымыз тек білім алып қана қоймай, жан-жақты дамиды!",

      progressTitle: "Оқушылардың жетістіктері мен прогресін бақылау",
      progressMethods: [
        {
          title: "Күнделікті есеп нәтижелері",
          text: "Әр сабақтан кейін оқушылардың тапсырмаларды орындау деңгейі мен нәтижелері арнайы есеп арқылы тіркеледі."
        },
        {
          title: "Сабақтағы белсенділік",
          text: "Оқушылардың күнделікті сабаққа қатысуы мен белсенділігі мұқият қадағаланып, олардың сабақ барысындағы әрекеттері есепке алынады."
        },
        {
          title: "Апталық тест нәтижелері",
          text: "Әр апта сайын өткізілетін тест нәтижелері арқылы оқушылардың өткен тақырыптарды меңгеру деңгейі анықталады."
        },
        {
          title: "Айлық тест нәтижелері",
          text: "Ай сайынғы тесттер оқушылардың жалпы прогресін бағалау үшін пайдаланылады. Бұл тәсіл олардың ұзақ мерзімді дамуын қадағалауға мүмкіндік береді."
        },
        {
          title: "Академиялық үлгерімнен тыс бағалау",
          text: "Оқушылардың жарыстар мен ойындарға қатысу белсенділігі, шығармашылық және әлеуметтік белсенділігі де есепке алынып, жан-жақты дамуы бағаланады."
        }
      ],
      progressOutro: "✨ Бұл кешенді әдістер оқушылардың тек білім деңгейін емес, сонымен қатар олардың жалпы дамуы мен жетістіктерін толық бақылауға мүмкіндік береді!",
      
      contactTitle: "Бізбен байланысыңыз",
      namePlaceholder: "Атыңыз",
      emailPlaceholder: "Email",
      phonePlaceholder: "Телефон",
      messagePlaceholder: "Хабарлама",
      sendMessage: "Хабарлама жіберу",
      
      nav: {
        home: "Басты бет",
        about: "Біз туралы",
        contacts: "Байланыс"
      },
      
      docs: {
        title: "Құжаттар",
        privacy: "Құпиялылық саясаты",
        offer: "Жария ұсыныс"
      },
      
      contactInfo: {
        title: "Байланыс ақпараты",
        email: "Email: office@restartonline.kz",
        phone: "Телефон: +7 747 465 0464",
        address: "Мекенжай: Алматы қ., Қазақстан"
      },
      
      footer: "© 2023 Restart Мектебі. Барлық құқықтар қорғалған."
    },
    ru: {
      heroTitle: "Restart Online - онлайн образование нового поколения",
      heroSubtitle: "Перезапустите свою карьеру с нашими образовательными программами",
      startLearning: "Начать обучение",
      learnMore: "Узнать больше",
      
      advantagesTitle: "Наши преимущества",
      
      advantages: [
        {
          icon: "📱",
          title: "Мобильное обучение",
          text: "Учитесь в любое время и в любом месте с нашим адаптивным приложением"
        },
        {
          icon: "👨‍🏫",
          title: "Опытные преподаватели",
          text: "Наши эксперты имеют практический опыт работы в своих областях"
        },
        {
          icon: "🎓",
          title: "Сертификация",
          text: "Получите сертификат, который признается работодателями"
        }
      ],
      
      aboutTitle: "О нашей платформе",
      aboutText1: "Restart Online - это современная образовательная платформа, которая предлагает курсы по самым востребованным направлениям. Мы помогаем студентам получить практические навыки, которые можно сразу применить в работе.",
      aboutText2: "Наша миссия - сделать качественное образование доступным для всех. Мы используем передовые технологии обучения и постоянно обновляем наши программы в соответствии с последними тенденциями.",
      contactUs: "Связаться с нами",
      
      // Новые секции
      valuesTitle: "Наши ценности",
      values: [
        {
          number: "1️⃣",
          title: "Развитие личности",
          text: "Помогаем раскрыть потенциал каждого ученика, учитывая его индивидуальные особенности."
        },
        {
          number: "2️⃣",
          title: "Обучение на протяжении всей жизни",
          text: "Мы учим не только теории, но и знаниям и навыкам, которые можно применять в жизни, и пропагандируем непрерывное обучение."
        },
        {
          number: "3️⃣",
          title: "Ответственность",
          text: "Воспитание сознательных граждан, способных использовать свои знания на благо общества - наша главная ценность."
        },
        {
          number: "4️⃣",
          title: "Творческое мышление и инновации",
          text: "Мы способствуем развитию творческих способностей наших учеников и помогаем им адаптироваться к работе с современными технологиями, предлагая новые идеи и находя эффективные решения."
        },
        {
          number: "5️⃣",
          title: "Служение обществу",
          text: "Наши ученики - будущие лидеры, которые используют свои знания и навыки на благо общества."
        }
      ],

      programsTitle: "Добро пожаловать в нашу школу! 🎓✨",
      programsIntro: "Мы считаем важным не только передавать знания, но и адаптировать их к жизни. Поэтому мы включили в школьную программу следующие предметы, используя особый подход:",
      
      programs: [
        {
          icon: "🔢",
          title: "Математика",
          intro: "Математика - гимнастика для ума! С помощью этого предмета ученики оттачивают свои логические способности и учатся решать любые жизненные задачи.",
          text: "Этот предмет развивает логическое мышление и помогает легче осваивать любые предметы. Ученик, хорошо владеющий математикой:\n✔ Регулирует работу мозга и улучшает память.\n✔ Может легко принимать важные решения в жизни.\nМатематика - основа успешного будущего! 🌟"
        },
        {
          icon: "🌍",
          title: "Английский язык",
          intro: "Английский язык - это окно в мир!",
          text: "В качестве международного средства общения, наши ученики свободно говорят на этом языке и получают возможность достичь глобального успеха в будущем."
        },
        {
          icon: "💻",
          title: "Информатика и программирование",
          intro: "Для соответствия современным требованиям очень важны навыки ИКТ и программирования.",
          text: "Мы учим учеников секретам цифрового мира через курс Алгоритм."
        },
        {
          icon: "🧠",
          title: "Логические задачи",
          intro: "Логические задачи - основа творческого мышления.",
          text: "В этом курсе ученики развивают свои логические способности и учатся легко решать сложные задачи разного уровня."
        }
      ],

      programsOutro: "✨ С нами у вас есть возможность разностороннего развития!",

      approachTitle: "Наш подход",
      approaches: [
        {
          title: "Индивидуальная траектория развития",
          text: "Каждый ученик осваивает уроки по индивидуальной траектории развития. В этой системе задания даются в соответствии с уровнем ученика, что позволяет каждому ребенку полностью реализовать свой потенциал.\nКроме того, ученики могут обсуждать и находить решения своих проблем на индивидуальных занятиях с наставниками. Такой подход облегчает процесс обучения для учеников и делает его более результативным."
        },
        {
          title: "Постоянный контакт с родителями",
          text: "Наставник регулярно отправляет родителям информацию о ежедневных, еженедельных и ежемесячных успехах ученика. Это позволяет родителям отслеживать достижения своего ребенка и активно участвовать в процессе обучения."
        },
        {
          title: "Связь с классным руководителем",
          text: "Наставник поддерживает тесную связь с классным руководителем ученика в офлайн-школе. Это позволяет всесторонне отслеживать общую успеваемость ученика и учитывать его достижения в офлайн-школе."
        }
      ],

      activitiesTitle: "Специальные программы и мероприятия",
      activities: [
        {
          icon: "🎮",
          title: "Итоговые работы с элементами геймификации",
          text: "Мы повышаем интерес учеников, включая игровые элементы в процесс обучения. Тесты и задания для освоения пройденных тем представлены в игровой форме."
        },
        {
          icon: "🏆",
          title: "Еженедельные марафоны-соревнования",
          text: "Раз в неделю организуются марафоны-соревнования между учениками, с призами для повышения их активности и мотивации."
        },
        {
          icon: "📝",
          title: "Набор еженедельных и ежемесячных тестов",
          text: "Разработан набор тестов, позволяющих ученикам осваивать новые уроки на своем уровне. В зависимости от результатов тестов, ученики группируются и получают образование по своим индивидуальным уровням."
        },
        {
          icon: "📊",
          title: "Сбор портфолио и диагностика",
          text: "Небольшие достижения каждого ученика собираются в специальное портфолио. Этот подход позволяет отслеживать динамику развития ученика и выявлять его конкретные слабые стороны."
        },
        {
          icon: "🏅",
          title: "Олимпиады и соревнования",
          text: "Проводятся предметные олимпиады и различные соревнования для совершенствования знаний учеников и достижения высоких результатов."
        }
      ],

      thematicWeeksTitle: "Еженедельные тематические мероприятия",
      thematicWeeks: [
        "Первая неделя: Неделя искусственного интеллекта (ИИ)", 
        "Вторая неделя: Спортивная неделя", 
        "Третья неделя: Неделя информационно-коммуникационных технологий (ИКТ)", 
        "Неделя ТикТока: С целью правильного использования социальных сетей",
        "День недели: Общение только через аудио или только через видео"
      ],
      thematicWeeksOutro: "Эти недели повторяются и постоянно пополняются новым содержанием.",

      psychologyTitle: "Работа психолога и профориентолога",
      psychologyText: "Для помощи в личностном развитии учеников и выборе будущей профессии психологи и профориентологи оказывают постоянную поддержку. Мы проводим психологическое тестирование, индивидуальный анализ, даем рекомендации родителям и объясняем через видео. Специалисты по профориентации помогают ученикам с выбором будущей профессии.",

      activitiesOutro: "✨ Мы делаем все возможное, чтобы сделать образование интересным и эффективным. Наши ученики не только получают знания, но и всесторонне развиваются!",

      progressTitle: "Мониторинг достижений и прогресса учеников",
      progressMethods: [
        {
          title: "Результаты ежедневных отчетов",
          text: "После каждого урока уровень выполнения заданий и результаты учеников фиксируются через специальный отчет."
        },
        {
          title: "Активность на уроке",
          text: "Ежедневное посещение и активность учеников тщательно отслеживаются, и их действия во время урока учитываются."
        },
        {
          title: "Результаты еженедельных тестов",
          text: "Результаты еженедельных тестов определяют уровень освоения учениками пройденных тем."
        },
        {
          title: "Результаты ежемесячных тестов",
          text: "Ежемесячные тесты используются для оценки общего прогресса учеников. Этот метод позволяет отслеживать их долгосрочное развитие."
        },
        {
          title: "Оценка помимо академической успеваемости",
          text: "Учитывается активность учеников в соревнованиях и играх, творческая и социальная активность, и оценивается их всестороннее развитие."
        }
      ],
      progressOutro: "✨ Эти комплексные методы позволяют полностью контролировать не только уровень знаний учеников, но и их общее развитие и достижения!",
      
      contactTitle: "Связаться с нами",
      namePlaceholder: "Имя",
      emailPlaceholder: "Email",
      phonePlaceholder: "Телефон",
      messagePlaceholder: "Сообщение",
      sendMessage: "Отправить сообщение",
      
      nav: {
        home: "Главная",
        about: "О нас",
        contacts: "Контакты"
      },
      
      docs: {
        title: "Документы",
        privacy: "Политика конфиденциальности",
        offer: "Публичная оферта"
      },
      
      contactInfo: {
        title: "Контакты",
        email: "Email: office@restartonline.kz",
        phone: "Телефон: +7 747 465 0464",
        address: "Адрес: г. Алматы, Казахстан"
      },
      
      footer: "© 2023 Restart Online. Все права защищены."
    }
  };

  // Текущий контент в зависимости от выбранного языка
  const currentContent = content[language];

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-container">
          <h1 className="hero-title">{currentContent.heroTitle}</h1>
          <p className="hero-subtitle">{currentContent.heroSubtitle}</p>
          <div className="hero-buttons">
            <a href="#contact" className="btn-primary">{currentContent.startLearning}</a>
            <a href="#about" className="btn-secondary">{currentContent.learnMore}</a>
          </div>
        </div>
      </section>

      {/* Advantages Section */}
      <section className="advantages-section" id="advantages">
        <div className="advantages-container">
          <h2 className="section-title">{currentContent.advantagesTitle}</h2>
          <div className="features-container">
            {currentContent.advantages.map((advantage, index) => (
              <div className="feature-card" key={index}>
                <div className="feature-icon">{advantage.icon}</div>
                <h3 className="feature-title">{advantage.title}</h3>
                <p className="feature-text">{advantage.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section" id="about">
        <div className="about-container">
          <div className="about-image">
            <img src="/images/expectation.jpg" alt="О нас" />
          </div>
          <div className="about-content">
            <h2 className="about-title">{currentContent.aboutTitle}</h2>
            <p className="about-text">{currentContent.aboutText1}</p>
            <p className="about-text">{currentContent.aboutText2}</p>
            <a href="#contact" className="btn-primary">{currentContent.contactUs}</a>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="values-section" id="values">
        <h2 className="section-title">{currentContent.valuesTitle}</h2>
        <div className="values-container">
          {currentContent.values.map((value, index) => (
            <div className="value-card" key={index}>
              <div className="value-number">{value.number}</div>
              <h3 className="value-title">{value.title}</h3>
              <p className="value-text">{value.text}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Programs Section */}
      <section className="programs-section" id="programs">
        <div className="programs-container">
          <h2 className="section-title">{currentContent.programsTitle}</h2>
          <p className="programs-intro">{currentContent.programsIntro}</p>
          
          <div className="programs-list">
            {currentContent.programs.map((program, index) => (
              <div className="program-item" key={index}>
                <div className="program-icon">{program.icon}</div>
                <div className="program-content">
                  <h3 className="program-title">{program.title}</h3>
                  <p className="program-intro"><strong>{program.intro}</strong></p>
                  <p className="program-text" dangerouslySetInnerHTML={{ __html: program.text.replace(/\n/g, '<br/>') }} />
                </div>
              </div>
            ))}
          </div>
          
          <p className="programs-outro">{currentContent.programsOutro}</p>
        </div>
      </section>

      {/* Approach Section */}
      <section className="approach-section" id="approach">
        <div className="approach-container">
          <h2 className="section-title">{currentContent.approachTitle}</h2>
          
          <div className="approaches-list">
            {currentContent.approaches.map((approach, index) => (
              <div className="approach-item" key={index}>
                <h3 className="approach-title">{index + 1}. {approach.title}</h3>
                <p className="approach-text" dangerouslySetInnerHTML={{ __html: approach.text.replace(/\n/g, '<br/>') }} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Activities Section */}
      <section className="activities-section" id="activities">
        <div className="activities-container">
          <h2 className="section-title">{currentContent.activitiesTitle}</h2>
          
          <div className="activities-list">
            {currentContent.activities.map((activity, index) => (
              <div className="activity-item" key={index}>
                <div className="activity-icon">{activity.icon}</div>
                <div className="activity-content">
                  <h3 className="activity-title">{activity.title}</h3>
                  <p className="activity-text">{activity.text}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="thematic-weeks">
            <h3 className="thematic-title">{currentContent.thematicWeeksTitle}</h3>
            <ul className="weeks-list">
              {currentContent.thematicWeeks.map((week, index) => (
                <li key={index}>{week}</li>
              ))}
            </ul>
            <p className="thematic-outro">{currentContent.thematicWeeksOutro}</p>
          </div>

          <div className="psychology-section">
            <h3 className="psychology-title">{currentContent.psychologyTitle}</h3>
            <p className="psychology-text">{currentContent.psychologyText}</p>
          </div>
          
          <p className="activities-outro">{currentContent.activitiesOutro}</p>
        </div>
      </section>

      {/* Progress Section */}
      <section className="progress-section" id="progress">
        <div className="progress-container">
          <h2 className="section-title">{currentContent.progressTitle}</h2>
          
          <div className="progress-methods">
            {currentContent.progressMethods.map((method, index) => (
              <div className="progress-method" key={index}>
                <h3 className="method-title">{method.title}</h3>
                <p className="method-text">{method.text}</p>
              </div>
            ))}
          </div>
          
          <p className="progress-outro">{currentContent.progressOutro}</p>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section" id="contact">
        <h2 className="section-title">{currentContent.contactTitle}</h2>
        <div className="contact-container">
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name" className="form-label">{currentContent.namePlaceholder}</label>
              <input type="text" id="name" className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">{currentContent.emailPlaceholder}</label>
              <input type="email" id="email" className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone" className="form-label">{currentContent.phonePlaceholder}</label>
              <input type="tel" id="phone" className="form-control" required />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="form-label">{currentContent.messagePlaceholder}</label>
              <textarea id="message" className="form-control form-textarea" required></textarea>
            </div>
            <button type="submit" className="btn-submit">{currentContent.sendMessage}</button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logo} alt="Restart Online Logo" />
          </div>
          
          <div className="footer-links">
            <h3 className="footer-title">{currentContent.nav.home}</h3>
            <Link to="/" className="footer-link">{currentContent.nav.home}</Link>
            <a href="#about" className="footer-link">{currentContent.nav.about}</a>
            <a href="#values" className="footer-link">{currentContent.valuesTitle}</a>
            <a href="#programs" className="footer-link">{currentContent.programsTitle}</a>
            <a href="#contact" className="footer-link">{currentContent.nav.contacts}</a>
          </div>
          
          <div className="footer-links">
            <h3 className="footer-title">{currentContent.docs.title}</h3>
            <Link to="/privacy-policy" className="footer-link">{currentContent.docs.privacy}</Link>
            <Link to="/offer" className="footer-link">{currentContent.docs.offer}</Link>
            <a href="#approach" className="footer-link">{currentContent.approachTitle}</a>
            <a href="#activities" className="footer-link">{currentContent.activitiesTitle}</a>
            <a href="#progress" className="footer-link">{currentContent.progressTitle}</a>
          </div>
          
          <div className="footer-contact">
            <h3 className="footer-title">{currentContent.contactInfo.title}</h3>
            <p>{currentContent.contactInfo.email}</p>
            <p><a href="tel:+77474650464" className="contact-phone">+7 747 465 0464</a></p>
            <p>{currentContent.contactInfo.address}</p>
            
            <div className="footer-social">
              <a href="https://wa.me/77474650464" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="https://instagram.com/restart_online_kz" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://t.me/restart_online_kz" target="_blank" rel="noopener noreferrer" className="social-icon">
                <i className="fab fa-telegram"></i>
              </a>
            </div>
          </div>
          
          <div className="footer-bottom">
            <p>{currentContent.footer}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
  