import React, { useContext } from 'react';
import './PrivacyPolicy.css'; // We'll create this CSS file
import { LanguageContext } from '../contexts/LanguageContext';

function PrivacyPolicy() {
  const { language } = useContext(LanguageContext);

  // Контент на казахском и русском языках
  const content = {
    kz: {
      title: "www.restartonline.kz интернет-порталының пайдаланушыларының құпиялылық саясаты",
      intro: "Бұл Жеке деректерді қорғау туралы келісім (бұдан әрі - Құпиялылық саясаты) www.restartonline.kz домендік атауында орналасқан интернет-порталды пайдалану кезінде Restart Online интернет-порталынан алуы мүмкін барлық ақпаратқа қатысты қолданылады.",
      
      sections: [
        {
          title: "1. Терминдерді анықтау",
          content: [
            "1.1. Бұл Құпиялылық саясатында келесі терминдер қолданылады:",
            ["Сайт әкімшілігі", "ЖШС \"BRAND ONLINE KZ\" атынан әрекет ететін, жеке деректерді өңдеуді ұйымдастыратын уәкілетті қызметкерлер."],
            ["Пайдаланушы", "Restart Online компаниясының сайтын пайдаланатын тұлға."],
            ["Жеке деректер", "Пайдаланушы туралы кез келген ақпарат (аты, телефон, email, IP-мекенжай, құрылғы, оқу прогресі)."],
            ["Жеке деректерді өңдеу", "деректерді жинау, сақтау, пайдалану, беру және жою."],
            ["Жеке деректердің құпиялылығы", "Пайдаланушының келісімінсіз деректерді құпия сақтау талабы."],
            ["Cookies", "Пайдаланушының құрылғысында сақталатын деректер фрагменттері."],
            ["IP-мекенжай", "құрылғының бірегей желілік мекенжайы."]
          ]
        },
        {
          title: "2. Жалпы ережелер",
          content: [
            "2.1. Сайтты пайдалану Пайдаланушының осы Құпиялылық саясатымен келісімін білдіреді.",
            "2.2. Саясат шарттарымен келіспеген жағдайда, Пайдаланушы сайтты пайдалануды тоқтатуы керек.",
            "2.3. Саясат тек restartonline.kz сайтына қолданылады. Біз басқа сайттар үшін жауапты емеспіз.",
            "2.4. Біз пайдаланушының жеке деректерінің дұрыстығын тексермейміз."
          ]
        },
        {
          title: "3. Құпиялылық саясатының мәні",
          content: [
            "3.1. Саясат Әкімшіліктің Пайдаланушылардың жеке деректерін қорғау бойынша міндеттемелерін анықтайды.",
            "3.2. Біз келесі деректерді өңдейміз:",
            ["Аты-жөні;", "Телефон нөірі;", "Email;", "Құрылғы деректері (IP-мекенжай, браузер);", "Оқу прогресі."],
            "3.3. Сайтқа кіру кезінде автоматты түрде жиналады:",
            ["IP-мекенжай;", "Cookies деректері;", "Браузер туралы ақпарат;", "Кіру уақыты;", "Бет мекенжайы және реферер."],
            "Cookies өшіру әкімшілік панельдің жұмысына әсер етуі мүмкін."
          ]
        },
        {
          title: "4. Жеке ақпаратты жинау мақсаттары",
          content: [
            "Деректер келесі мақсаттарда өңделеді:",
            ["Пайдаланушыны сәйкестендіру;", "Курстарға қол жеткізу;", "Кері байланыс;", "Қызмет көрсету бойынша міндеттемелерді орындау;", "Деректердің дұрыстығын растау;", "Сервисті жақсарту;", "Жаңалықтар мен акциялар туралы хабарлау (Пайдаланушының келісімімен)."]
          ]
        },
        {
          title: "5. Жеке ақпаратты өңдеу әдістері мен мерзімдері",
          content: [
            "5.1. Деректер заңды әдістермен мерзімсіз өңделеді.",
            "5.2. Үшінші тараптарға беру тек тапсырыстарды орындау үшін мүмкін.",
            "5.3. Мемлекеттік органдарға беру заңнамада белгіленген жағдайларда мүмкін.",
            "5.4. Деректер жоғалған жағдайда біз Пайдаланушыға хабарлаймыз.",
            "5.5. Деректер Қазақстандағы Yandex серверлерінде сақталады.",
            "5.6. Маркетингтік мақсаттарда деректер үшінші тұлғаларға берілмейді."
          ]
        },
        {
          title: "6. Тараптардың міндеттері",
          content: [
            "Пайдаланушы міндетті:",
            ["Шынайы деректерді ұсыну;", "Қажет болған жағдайда деректерді жаңарту."],
            "Әкімшілік міндетті:",
            ["Деректерді тек көрсетілген мақсаттар шеңберінде пайдалану;", "Деректердің құпиялылығын сақтау;", "Деректерді техникалық және ұйымдастырушылық шаралармен қорғау;", "Пайдаланушының немесе билік органдарының сұрауы бойынша деректерді бұғаттау."]
          ]
        },
        {
          title: "7. Тараптардың жауапкершілігі",
          content: [
            "7.1. Әкімшілік жеке деректерді заңсыз пайдаланғаны үшін жауапты.",
            "7.2. Әкімшілік деректерді жоғалту жағдайларында жауапты емес, егер:",
            ["Деректер жоғалту алдында жалпыға қолжетімді болса;", "Деректер үшінші тұлғалардан алынса;", "Пайдаланушы ашылуға келісім берсе."]
          ]
        },
        {
          title: "8. Дауларды шешу",
          content: [
            "8.1. Даулар келіссөздер арқылы шешіледі.",
            "8.2. Реттеу мүмкін болмаған жағдайда, дау Қазақстан Республикасының сотына жіберіледі."
          ]
        },
        {
          title: "9. Қосымша шарттар",
          content: [
            "9.1. Біз Пайдаланушының келісімінсіз Саясатқа өзгерістер енгізуге құқылымыз.",
            "9.2. Саясаттың жаңа нұсқасы restartonline.kz сайтында жарияланған сәттен бастап күшіне енеді.",
            "9.3. Сұрақтар мен ұсыныстарды <a href=\"mailto:office@restartonline.kz\" className=\"contact-email\">office@restartonline.kz</a> мекенжайына жіберуге болады.",
            "9.4. Саясаттың ағымдағы нұсқасы restartonline.kz сайтында орналастырылған."
          ]
        }
      ]
    },
    ru: {
      title: "Политика конфиденциальности пользователей интернет-портала www.restartonline.kz",
      intro: "Настоящее Соглашение о защите персональных данных пользователей (далее — Политика конфиденциальности) действует в отношении всей информации, которую интернет-портал, расположенный на доменном имени www.restartonline.kz, может получить о Пользователе во время использования Пользователем интернет-портала Restart Online.",
      
      sections: [
        {
          title: "1. Определение терминов",
          content: [
            "1.1. В настоящей Политике конфиденциальности используются следующие термины:",
            ["Администрация сайта", "уполномоченные сотрудники, действующие от имени ТОО \"BRAND ONLINE KZ\", которые организуют обработку персональных данных."],
            ["Пользователь", "лицо, использующее сайт компании Restart Online."],
            ["Персональные данные", "любая информация о Пользователе (имя, телефон, email, IP-адрес, устройство, прогресс обучения)."],
            ["Обработка персональных данных", "сбор, хранение, использование, передача и удаление данных."],
            ["Конфиденциальность персональных данных", "требование сохранять данные в тайне без согласия Пользователя."],
            ["Cookies", "фрагменты данных, сохраняемые на устройстве Пользователя."],
            ["IP-адрес", "уникальный сетевой адрес устройства."]
          ]
        },
        {
          title: "2. Общие положения",
          content: [
            "2.1. Использование сайта означает согласие Пользователя с данной Политикой конфиденциальности.",
            "2.2. В случае несогласия с условиями Политики Пользователь должен прекратить использование сайта.",
            "2.3. Политика применяется только к сайту restartonline.kz. Мы не отвечаем за другие сайты.",
            "2.4. Мы не проверяем достоверность персональных данных Пользователя."
          ]
        },
        {
          title: "3. Предмет политики конфиденциальности",
          content: [
            "3.1. Политика определяет обязательства Администрации по защите персональных данных Пользователей.",
            "3.2. Мы обрабатываем следующие данные:",
            ["Имя и фамилия;", "Номер телефона;", "Email;", "Данные устройства (IP-адрес, браузер);", "Прогресс обучения."],
            "3.3. При посещении сайта автоматически собираются:",
            ["IP-адрес;", "Данные из cookies;", "Информация о браузере;", "Время доступа;", "Адрес страницы и реферер."],
            "Отключение cookies может повлиять на работу административной панели."
          ]
        },
        {
          title: "4. Цели сбора персональной информации",
          content: [
            "Данные обрабатываются для:",
            ["Идентификации Пользователя;", "Предоставления доступа к курсам;", "Обратной связи;", "Исполнения обязательств по оказанию услуг;", "Подтверждения достоверности данных;", "Улучшения сервиса;", "Информирования о новинках и акциях (с согласия Пользователя)."]
          ]
        },
        {
          title: "5. Способы и сроки обработки персональной информации",
          content: [
            "5.1. Данные обрабатываются без ограничения срока, законными способами.",
            "5.2. Передача третьим лицам возможна только для выполнения заказов.",
            "5.3. Передача государственным органам возможна в случаях, установленных законодательством.",
            "5.4. При утрате данных мы уведомляем Пользователя.",
            "5.5. Данные хранятся на серверах Yandex в Казахстане.",
            "5.6. Данные не передаются третьим лицам в маркетинговых целях."
          ]
        },
        {
          title: "6. Обязательства сторон",
          content: [
            "Пользователь обязан:",
            ["Предоставлять достоверные данные;", "Обновлять данные при необходимости."],
            "Администрация обязана:",
            ["Использовать данные только в рамках заявленных целей;", "Сохранять конфиденциальность данных;", "Защищать данные техническими и организационными мерами;", "Блокировать данные по запросу Пользователя или органов власти."]
          ]
        },
        {
          title: "7. Ответственность сторон",
          content: [
            "7.1. Администрация несёт ответственность за неправомерное использование персональных данных.",
            "7.2. Администрация не несёт ответственности за утрату данных в случаях, если:",
            ["Данные стали общедоступными до их утраты;", "Данные получены от третьих лиц;", "Пользователь дал согласие на раскрытие."]
          ]
        },
        {
          title: "8. Разрешение споров",
          content: [
            "8.1. Споры решаются путем переговоров.",
            "8.2. При невозможности урегулирования спор передаётся в суд Республики Казахстан."
          ]
        },
        {
          title: "9. Дополнительные условия",
          content: [
            "9.1. Мы вправе вносить изменения в Политику без согласия Пользователя.",
            "9.2. Новая версия Политики вступает в силу с момента публикации на сайте restartonline.kz.",
            "9.3. Вопросы и предложения направлять на <a href=\"mailto:office@restartonline.kz\" className=\"contact-email\">office@restartonline.kz</a>.",
            "9.4. Актуальная версия Политики размещена на сайте restartonline.kz."
          ]
        }
      ]
    }
  };

  // Текущий контент в зависимости от выбранного языка
  const currentContent = content[language];

  const renderContentItem = (item) => {
    if (Array.isArray(item)) {
      return (
        <ul className="terms-list">
          {item.map((listItem, index) => (
            <li key={index}>{typeof listItem === 'string' && listItem.includes("<a") 
              ? <span dangerouslySetInnerHTML={{ __html: listItem }} />
              : <><strong>{listItem.split(" ")[0]}</strong> {listItem.split(" ").slice(1).join(" ")}</>
            }</li>
          ))}
        </ul>
      );
    } else if (item.includes("<a")) {
      return <p dangerouslySetInnerHTML={{ __html: item }} />;
    } else {
      return <p>{item}</p>;
    }
  };

  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <h1 className="privacy-policy-title">{currentContent.title}</h1>
        <p className="privacy-policy-intro">{currentContent.intro}</p>

        {currentContent.sections.map((section, index) => (
          <div className="privacy-policy-section" key={index}>
            <h2 className="section-title">{section.title}</h2>
            {section.content.map((contentItem, contentIndex) => (
              <React.Fragment key={contentIndex}>
                {renderContentItem(contentItem)}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
